import { useUser } from '@auth0/nextjs-auth0'
import { SwitchHorizontalIcon } from '@heroicons/react/outline'
import { Button } from 'components/Button'
import { Image } from 'components/Image'
import { Text } from 'components/Text'
import { PASS_TYPE } from 'constants/index'
import { useRouter } from 'hooks'
import { default as React, useCallback, useEffect, useState } from 'react'
import { ImCancelCircle } from 'react-icons/im'
import {
    ClubNameWrapper,
    ClubSectionWrapper,
    ModalBody,
    ModalWrapper,
    SectionWrapper,
} from './index.style'

const FanPass: React.FC<any> = ({
    homeClubId,
    homeClubName,
    homeClubImage,
    hasSubHomeClub = false,
    awayClubId = null,
    awayClubName = null,
    awayClubImage = null,
    hasSubAwayClub = false,
    gameDay = false,
    homePriceId,
    awayPriceId,
    homePassId,
    awayPassId,
    homePassPrice,
    awayPassPrice,
    showModal,
    onClose,
}) => {
    const { move, asPath }: any = useRouter()
    const { user } = useUser()
    const [isSwitchAwayClub, setSwitchAwayClub] = useState<boolean>(false)

    const handleMenuClick = (to: any) => {
        move(to)
    }

    const handleSwitchClub = () => {
        setSwitchAwayClub((prev) => !prev)
    }

    useEffect(() => {
        setSwitchAwayClub(!homePassId)
    }, [homePassId, showModal])

    const escapeFunction = useCallback(
        (event: any) => {
            if (event.key === 'Escape') {
                onClose()
            }
        },
        [onClose],
    )

    useEffect(() => {
        if (showModal) {
            document.addEventListener('keyup', escapeFunction, false)
        }

        return () => {
            document.removeEventListener('keyup', escapeFunction, false)
        }
    }, [escapeFunction, showModal])

    return (
        <div onClick={() => onClose()}>
            <ModalWrapper
                open={showModal}
                footer={null}
                title={null}
                closeIcon={<ImCancelCircle onClick={() => onClose()} />}
            >
                <ModalBody
                    onClick={(e) => {
                        e.stopPropagation()
                    }}
                >
                    <ClubSectionWrapper>
                        {(isSwitchAwayClub
                            ? !!awayClubImage
                            : !!homeClubImage) && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%',
                                }}
                            >
                                <Image
                                    src={
                                        isSwitchAwayClub
                                            ? awayClubImage
                                            : homeClubImage
                                    }
                                    width={200}
                                    height={200}
                                    alt=""
                                />
                            </div>
                        )}
                        <SectionWrapper
                            flexDirection="column"
                            gap="10px"
                            alignItems="center"
                        >
                            <ClubNameWrapper
                                fSize={2.2}
                                fWeight={800}
                                lHeight={40}
                            >
                                {isSwitchAwayClub ? awayClubName : homeClubName}
                            </ClubNameWrapper>
                            <Text fSize={1.4} fColor="white" tAlign="center">
                                {'Digital Fan Pass'}
                                {` $${
                                    isSwitchAwayClub
                                        ? awayPassPrice
                                        : homePassPrice
                                } monthly incl. GST`}
                            </Text>
                        </SectionWrapper>
                    </ClubSectionWrapper>

                    {gameDay && homePassId && awayPassId && (
                        <SectionWrapper
                            style={{ cursor: 'pointer' }}
                            padding="10px 0px"
                            onClick={handleSwitchClub}
                        >
                            <SwitchHorizontalIcon
                                aria-hidden="true"
                                style={{
                                    height: '1.5rem',
                                    width: '1.5rem',
                                    color: 'white',
                                }}
                            />
                            <Text
                                fSize={1}
                                fColor="white"
                                padding="0px 0px 10px 0px"
                            >
                                {'Switch Club'}
                            </Text>
                        </SectionWrapper>
                    )}

                    <SectionWrapper
                        flexDirection="row"
                        padding="5% 0px 15px 0px"
                        gap="15px"
                    >
                        {!user && (
                            <>
                                <Button
                                    variant="outlined"
                                    width="45%"
                                    style={{
                                        border: `1px solid red`,
                                        borderRadius: '5px',
                                    }}
                                    onClick={() =>
                                        handleMenuClick(
                                            `/api/auth/login?returnTo=${asPath}`,
                                        )
                                    }
                                >
                                    {'Login'}
                                </Button>
                                <Button
                                    variant="outlined"
                                    width="45%"
                                    style={{
                                        border: `1px solid red`,
                                        borderRadius: '5px',
                                    }}
                                    onClick={() =>
                                        handleMenuClick(
                                            `/api/auth/signup?returnTo=${asPath}`,
                                        )
                                    }
                                >
                                    {'Signup'}
                                </Button>
                            </>
                        )}
                        <form
                            action={`/api/stripe/checkout_session?returnURL=${asPath}`}
                            method="POST"
                            target="_self"
                            style={{ width: '100%' }}
                        >
                            {!isSwitchAwayClub && (
                                <>
                                    <input
                                        type="hidden"
                                        name="price_id"
                                        value={homePriceId}
                                    />
                                    <input
                                        type="hidden"
                                        name="pass_name"
                                        value={homeClubName}
                                    />
                                </>
                            )}
                            {isSwitchAwayClub && (
                                <>
                                    <input
                                        type="hidden"
                                        name="price_id"
                                        value={awayPriceId}
                                    />
                                    <input
                                        type="hidden"
                                        name="pass_name"
                                        value={awayClubName}
                                    />
                                </>
                            )}
                            <input
                                type="hidden"
                                name="pass_id"
                                value={
                                    isSwitchAwayClub ? awayPassId : homePassId
                                }
                            />
                            <input
                                type="hidden"
                                name="pass_type"
                                value={PASS_TYPE.FAN_PASS}
                            />
                            {user && (
                                <SectionWrapper
                                    flexDirection="column"
                                    gap="10px"
                                >
                                    <Button
                                        type="submit"
                                        role="link"
                                        bColor="_primary"
                                        width="100%"
                                    >
                                        {!isSwitchAwayClub &&
                                            (hasSubHomeClub
                                                ? 'Manage Subscriptions'
                                                : 'Support Club')}
                                        {isSwitchAwayClub &&
                                            (hasSubAwayClub
                                                ? 'Manage Subscriptions'
                                                : 'Support Club')}
                                    </Button>
                                    <Button
                                        role="link"
                                        width="100%"
                                        onClick={() => onClose()}
                                    >
                                        {`Skip For Now`}
                                    </Button>
                                </SectionWrapper>
                            )}
                        </form>
                    </SectionWrapper>

                    <SectionWrapper flexDirection="column" padding="10px 0px">
                        <Text
                            fSize={1.1}
                            fColor="white"
                            padding="0px 0px 40px 0px"
                            lHeight={33}
                        >
                            {
                                "Hi there! You can keep watching this for free, but if you'd like to chip in and support your club or your league, consider purchasing a Digital Fan Pass. Streaming requires effort, and your support makes it a little bit easier."
                            }
                        </Text>
                        <Text fSize={1.1} fColor="white" lHeight={33}>
                            {
                                "In return, we'll give you access to CLIP, SAVE and SHARE your favourite moments from your club's games."
                            }
                        </Text>
                    </SectionWrapper>

                    {!user && (
                        <SectionWrapper
                            flexDirection="column"
                            justifyContent="center"
                            padding="25px 0px 0px 0px"
                        >
                            <Text
                                fSize={1.08}
                                fWeight={700}
                                tAlign="center"
                                fColor="white"
                            >
                                {
                                    'Only logged-in users can subscribe to Digital Fan Passes'
                                }
                            </Text>

                            {/* <Link href={"/api/auth/login"}>
              <Button role="link" style={{ marginTop: 15 }} bColor="_primary" width="100%"  >Login</Button>
            </Link> */}
                        </SectionWrapper>
                    )}

                    {(!isSwitchAwayClub && hasSubHomeClub) ||
                    (isSwitchAwayClub && hasSubAwayClub) ? (
                        <SectionWrapper flexDirection="column">
                            <Text fSize={1} fColor="white">
                                {
                                    "You have already subscribed to this Club's Digital Fan Pass"
                                }
                            </Text>
                        </SectionWrapper>
                    ) : (
                        <></>
                    )}
                </ModalBody>
            </ModalWrapper>
        </div>
    )
}

export default FanPass
